
import { Component } from 'vue-property-decorator';
import Editable from '@/mixins/Editable';
import { mixins } from 'vue-class-component';
import VWrapper from '@/components/shared/VWrapper.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { Company } from '@/interfaces/models/Company';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import OpeningHourForm from '@/components/company/form/OpeningHourForm.vue';
import GeneralForm from '@/components/company/form/GeneralForm.vue';
import DeliveryTimeForm from '@/components/company/form/DeliveryTimeForm.vue';

const app = namespace('app');

const company = namespace('company');
@Component({
  components: { GeneralForm, VWrapper, OpeningHourForm, DeliveryTimeForm },
})
export default class EditCompany extends mixins(Editable, StackedForm, Notification) {
  @company.State('active') public active!: any;
  @company.Action('store') public store!: any;
  @company.Action('show') public show!: any;
  @company.Action('update') public update!: any;
  @company.Action('setActive') public setActive!: any;

  @app.State('venues') public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof GeneralForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
    openingHours: InstanceType<typeof OpeningHourForm> & { getData: () => any; isEmpty: () => boolean };
    deliveryHours: InstanceType<typeof DeliveryTimeForm> & { getData: () => any };
  };

  public timeout: any;

  public beforeMount() {
    if (this.active) {
      this.setActive(null);
    }
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('company');
      await this.show({ id: this.id });
      this.$stopLoading('company');
    }
  }

  get title() {
    return this.editing ? 'company.edit' : 'company.create';
  }

  public getData() {
    return {
      ...this.$refs.form.getData(),
      ...this.$refs.openingHours.getData(),
      ...this.$refs.deliveryHours.getData(),
    };
  }
  public async save() {
    Promise.all(await this.validate())
      .then(async (res: any) => {
        if (this.$refs.openingHours.isEmpty()) {
          this.notifyError('company.notification.emptyOpeningHours');
          return;
        }
        this.$startLoading('company.save');
        if (this.isValid(res)) {
          const data: Partial<Company> = this.getData();
          if (this.editing) {
            await this.update({ id: this.active._id, ...data });
          } else {
            await this.store(data);
          }
          this.$router.push({ name: 'company.index' });
        } else {
          this.notifyError('notification.form');
        }
      })
      .finally(() => this.$stopLoading('company.save'));
  }
}
