
import { Component } from 'vue-property-decorator';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import DeliveryTime from '@/components/shared/domain/VDeliveryTime.vue';
@Component({
  components: { DeliveryTime },
})
export default class DeliveryTimeForm extends mixins(StackedForm) {
  public validate() {
    return this.$refs.deliveryTime.validate();
  }

  public getData() {
    if (!this.$refs.deliveryTime) {
      return { deliveryTimes: [] };
    }
    return {
      deliveryTimes: this.$refs.deliveryTime.getHours(),
    };
  }

  public mounted(): void {
    this.$nextTick(() => {
      if (!this.initialValues) {
        return;
      }
      // @ts-ignore
      if (!this.initialValues.deliveryTimes) {
        return;
      }
      // @ts-ignore
      this.$refs.deliveryTime?.setHours(this.initialValues.deliveryTimes);
    });
  }
}
