
import { Component } from 'vue-property-decorator';
import Editable from '@/mixins/Editable';
import { mixins } from 'vue-class-component';
import VWrapper from '@/components/shared/VWrapper.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import AvailableHourForm from '@/components/subscription/AvailableHourForm.vue';
import LoyaltyProgramsForm from '@/components/loyaltyPrograms/LoyaltyProgramsForm.vue';
import { Loyalty } from '@/interfaces/models/Loyalty';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';

const app = namespace('app');

const loyalty = namespace('loyalty');
@Component({
  components: { LoyaltyProgramsForm, VWrapper, AvailableHourForm },
})
export default class EditLoyaltyPrograms extends mixins(Editable, StackedForm, Notification) {
  @loyalty.State('active') public active!: Loyalty;
  @loyalty.Action('store') public store!: any;
  @loyalty.Action('show') public show!: any;
  @loyalty.Action('update') public update!: any;

  @app.State('venues') public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof LoyaltyProgramsForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  public timeout: any;

  public async mounted() {
    if (this.editing) {
      await this.show({ id: this.id });
    }
  }
  get title() {
    return this.editing ? 'loyaltyPrograms.edit' : 'loyaltyPrograms.create';
  }

  public async save() {
    Promise.all(await this.validate())
      .then(async (res: any) => {
        this.$startLoading('loyaltyPrograms.save');
        if (this.isValid(res)) {
          const data: Partial<Loyalty> = this.$refs.form.getData();
          if (this.editing) {
            await this.update({ id: this.active._id, ...data });
          } else {
            await this.store(data);
          }
          this.$router.push({ name: 'loyaltyPrograms.index' });
        } else {
          this.notifyError('notification.form');
        }
      })
      .finally(() => this.$stopLoading('loyaltyPrograms.save'));
  }
}
