
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import CustomerGroup from '@/mixins/CustomerGroup';
import { LoyaltyType } from '@/enums/LoyaltyType';
import { Venue } from '@/interfaces/models/Venue';
import { Loyalty } from '@/interfaces/models/Loyalty';

@Component({
  components: { VFormBuilder },
})
export default class LoyaltyProgramsForm extends mixins(StackedForm, CustomerGroup) {
  @Prop({ type: Array, default: () => [] }) public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getData: () => any };
    clientCredentials: InstanceType<typeof VFormBuilder> & { form: any; getData: () => any; reset: () => void };
    rbiCredentials: InstanceType<typeof VFormBuilder> & { form: any; getData: () => any };
  };

  private customerGroup: string = '';
  public loyaltyType: LoyaltyType | null = null;

  public async mounted(): Promise<void> {
    this.loyaltyType = (this.initialValues as Loyalty)?.type;
    this.customerGroup = (this.initialValues as Loyalty)?.customerGroup;
  }

  get items() {
    return [
      {
        name: 'customerGroup',
        type: InputType.Select,
        label: 'loyaltyPrograms.form.customerGroup',
        items: this.scopedCustomerGroups,
        change: this.onCustomerGroupChange,
      },
      {
        name: 'type',
        type: InputType.Select,
        label: 'loyaltyPrograms.form.type',
        rules: 'required',
        items: this.types,
        change: this.onTypeChange,
      },
      {
        name: 'venue',
        type: InputType.Select,
        label: 'screensaver.form.venues',
        items: this.venueByCustomerGroup,
        itemValue: '_id',
        itemText: 'name',
        clearable: true,
      },
    ];
  }

  get venueByCustomerGroup() {
    const customerGroup: string | undefined = this.customerGroup || '';
    return this.venues.filter((venue: Venue) => venue?.customerGroup === customerGroup);
  }

  public onCustomerGroupChange(customerGroup: string) {
    this.customerGroup = customerGroup;
  }

  public async onTypeChange(type: LoyaltyType) {
    this.loyaltyType = type;
  }

  get clientCredentials() {
    return [
      { name: 'apikey', type: InputType.Text, label: 'loyaltyPrograms.form.apikey', rules: 'required' },
      { name: 'oauthId', type: InputType.Text, label: 'loyaltyPrograms.form.oauthId', rules: 'required' },
      { name: 'oauthSecret', type: InputType.Text, label: 'loyaltyPrograms.form.oauthSecret', rules: 'required' },
      { name: 'shopUuid', type: InputType.Text, label: 'loyaltyPrograms.form.shopUuid', rules: 'required' },
    ];
  }

  get rbiCredentials() {
    return [
      { name: 'operator', type: InputType.Text, label: 'loyaltyPrograms.form.operator', rules: 'required' },
      { name: 'posType', type: InputType.Text, label: 'loyaltyPrograms.form.posType', rules: 'required' },
      { name: 'storeId', type: InputType.Text, label: 'loyaltyPrograms.form.storeId', rules: 'required' },
      { name: 'supportingPos', type: InputType.Text, label: 'loyaltyPrograms.form.supportingPos', rules: 'required' },
    ];
  }

  get types() {
    return [
      { text: this.$t('loyaltyPrograms.form.piggy'), value: LoyaltyType.PIGGY },
      { text: this.$t('loyaltyPrograms.form.como'), value: LoyaltyType.COMO },
      { text: this.$t('loyaltyPrograms.form.internal'), value: LoyaltyType.INTERNAL },
      { text: this.$t('loyaltyPrograms.form.rbi'), value: LoyaltyType.RBI },
    ];
  }

  public getData() {
    const credentialsKey = this.loyaltyType === LoyaltyType.RBI ? 'rbiCredentials' : 'piggyClientCredentials';
    const credentials =
      this.loyaltyType === LoyaltyType.RBI ? this.$refs.rbiCredentials?.form : this.$refs.clientCredentials?.form;

    return { ...this.$refs.form.form, [credentialsKey]: credentials };
  }
}
