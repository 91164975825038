
import { Component } from 'vue-property-decorator';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { OpeningHour } from '@/interfaces/models/OpeningHour';

@Component({
  components: { VOpeningHour },
})
export default class AvailableHourForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VOpeningHour> & { hours: OpeningHour[] };
  };

  public validate() {
    // @ts-ignore
    return this.$refs.form.validate();
  }

  public getData() {
    // @ts-ignore
    return { availability: this.$refs.form.getHours() };
  }

  public mounted(): void {
    // @ts-ignore
    if (this.initialValues && this.initialValues.availability) {
      // @ts-ignore
      this.$refs.form.setHours(this.initialValues.availability);
    }
  }
}
